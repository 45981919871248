<template>
  <component :is="vehicleData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="vehicleData === undefined">
      <h4 class="alert-heading">Errore dati Veicolo</h4>
      <div class="alert-body">
        Nessun Veicolo trovato con questo ID. Controlla
        <b-link class="alert-link" :to="{ name: 'apps-vehicles-list' }"> Lista Veicoli </b-link>
        per altri Veicoli.
      </div>
    </b-alert>

    <template v-if="vehicleData" pills>
      <!-- Tab: Information -->
      <b-row>
        <b-col>
          <vehicles-edit-tab-information
            :vehicle-data="vehicleData"
            :headquarters="headquarters"
          />
        </b-col>
      </b-row>
    </template>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BCol, BRow } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import vehiclesStoreModule from '../vehiclesStoreModule'
import VehiclesEditTabInformation from './VehiclesEditTabInformation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import headquartersStoreModule from '@/views/apps/headquarters/headquartersStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BCol,
    BRow,

    VehiclesEditTabInformation,
  },
  setup() {
    const toast = useToast()
    const vehicleData = ref(null)

    const VEHICLES_APP_STORE_MODULE_NAME = 'app-vehicles'
    const HEADQUARTERS_APP_STORE_MODULE_NAME = 'app-headquarters'

    // Register module
    if (!store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) {
      store.registerModule(VEHICLES_APP_STORE_MODULE_NAME, vehiclesStoreModule)
    }
    if (!store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERS_APP_STORE_MODULE_NAME, headquartersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLES_APP_STORE_MODULE_NAME)
      if (store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(HEADQUARTERS_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-vehicles/fetchVehicle', { id: router.currentRoute.params.id })
      .then((response) => {
        vehicleData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    const headquarters = ref(null)
    const _headquarters = [
      {
        value: null,
        text: 'Seleziona una Sede',
      },
    ]
    store
      .dispatch('app-headquarters/fetchHeadquarters')
      .then(response => {
        response.data[0].forEach((headquarter, i) => {
          const _headquarter = {
            value: headquarter.id,
            text: headquarter.name,
          }
          _headquarters.push(_headquarter)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    headquarters.value = _headquarters

    return {
      vehicleData,
      headquarters
    }
  },
}
</script>
