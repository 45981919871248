<template>
  <b-card>
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form
      ref="form"
      class="p-2"
    >
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="TruckIcon"
            size="25"
            class="mr-2"
          />
          <h1>Modifica Veicolo</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-1"
          cols="12"
        >
          <b-img
            v-if="!mediaUrl"
            :src="vehicleData.image_path ? mediaEndpoint + vehicleData.image_path : ''"
            v-bind="vehicleData.image_path ? '' : blankProps"
            alt="Immagine Veicolo"
            class="vehicle_image"
          />
          <b-img
            v-if="mediaUrl"
            :src="mediaUrl"
            alt="Immagine Veicolo"
            class="vehicle_image"
          />
        </b-col>
        <b-col
          class="mb-5 text-center"
          cols="10"
        >
          <b-form-file
            v-model="mediaFile"
            :state="Boolean(mediaFile)"
            placeholder="Cambia Immagine scegliendo un File o trascinandolo qui..."
            drop-placeholder="Trascina il File qui..."
            accept="image/*"
            @input="previewMedia"
          />
        </b-col>
        <b-col
          class="mb-5"
          cols="2"
        >
          <b-button
            variant="outline-primary"
            @click="removePreview"
          >
            Rimuovi
          </b-button>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Nome Veicolo *"
            label-for="display_name"
          >
            <b-form-input
              id="display_name"
              v-model="vehicleData.display_name"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Anno Immatricolazione"
            label-for="factory_year"
          >
            <b-form-input
              id="factory_year"
              v-model="vehicleData.factory_year"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Tipologia *"
            label-for="type"
          >
            <b-form-select
              id="type"
              v-model="vehicleData.type"
              :options="vehicleTypes"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Alimentazione *"
            label-for="fuel_type"
          >
            <b-form-select
              id="fuel_type"
              v-model="vehicleData.fuel_type"
              :options="vehicleFuelTypes"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Modello *"
            label-for="model"
          >
            <b-form-input
              id="model"
              v-model="vehicleData.model"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="N° Posti"
            label-for="seat_number"
          >
            <b-form-input
              id="seat_number"
              v-model="vehicleData.seat_number"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="headquarters"
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Sede di Appartenenza"
            label-for="headquarter_id"
          >
            <b-form-select
              id="headquarter_id"
              v-model="vehicleData.headquarter_id"
              :options="headquarters"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
        >
          <b-form-group
            label="Note"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="vehicleData.note"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-2"
          cols="12"
        >
          <h2>Caratteristiche</h2>
          <label for="features">Premi Invio per inserire la Caratteristica</label>
          <b-form-tags
            v-model="vehicleData.features"
            input-id="features"
            class="mb-2"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateVehicle"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
            />
            <span v-if="!loading">Salva Cambiamenti</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BFormSelect,
  BFormTextarea,
  BFormTags,
  BLink,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormTags,
    BLink,
    BImg,
    BSpinner,
  },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
    headquarters: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      vehicleTypes: [
        { value: null, text: 'Seleziona una Tipologia' },
        { value: 'Auto', text: 'Auto' },
        { value: 'Furgone', text: 'Furgone' },
        { value: 'Camion', text: 'Camion' },
      ],
      vehicleFuelTypes: [
        { value: null, text: 'Seleziona un tipo di Alimentazione' },
        { value: 'diesel', text: 'Diesel' },
        { value: 'gasoline', text: 'Benzina' },
        { value: 'methanol', text: 'Metano' },
        { value: 'gpl', text: 'GPL' },
        { value: 'electric', text: 'Elettrica' },
        { value: 'hydrogen', text: 'Idrogeno' },
      ],
      blankProps: {
        blank: true,
        blankColor: '#777',
        height: 300,
      },
      loading: false,
      mediaFile: null,
      mediaUrl: null,
    }
  },
  computed: {
    mediaEndpoint() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  methods: {
    previewMedia() {
      if (this.mediaFile) this.mediaUrl = URL.createObjectURL(this.mediaFile)
    },
    removePreview() {
      URL.revokeObjectURL(this.mediaUrl)
      this.mediaUrl = null
      this.mediaFile = null
    },
    goBack() {
      this.$router.go(-1)
    },
    reset() {
      this.$refs.form.reset()
    },
    updateVehicle() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      const { id } = router.currentRoute.params
      const formData = new FormData()
      formData.append('display_name', this.vehicleData.display_name || null)
      formData.append('factory_year', this.vehicleData.factory_year || null)
      formData.append('type', this.vehicleData.type || null)
      formData.append('fuel_type', this.vehicleData.fuel_type || null)
      formData.append('model', this.vehicleData.model || null)
      formData.append('seat_number', this.vehicleData.seat_number || null)
      formData.append('note', this.vehicleData.note || null)
      formData.append('features', this.vehicleData.features)
      if (this.vehicleData.headquarter_id) formData.append('headquarter_id', this.vehicleData.headquarter_id)
      if (this.mediaFile) formData.append('image', this.mediaFile)
      store
        .dispatch('app-vehicles/updateVehicle', { id, formData })
        .then(() => {
          router.replace({ name: 'apps-vehicles-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Veicolo #${id} modificato con successo`,
                icon: 'TruckIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.vehicleData.display_name) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Nome',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Nome del Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.vehicleData.type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Tipologia',
            icon: 'AlertTriangleIcon',
            text: 'Seleziona una Tipologia di Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.vehicleData.fuel_type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Alimentazione',
            icon: 'AlertTriangleIcon',
            text: 'Seleziona un tipo di Alimentazione del Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.vehicleData.model) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Modello',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Modello del Veicolo per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
.vehicle_image {
  width: 100%;
  max-height: 500px;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Sfoglia';
}
</style>
